import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Alert, Button, Dropdown, Table } from "react-bootstrap";
import querystring from '../../libs/querystringLib';
import { ActionsToggle } from "../../components/ActionsToggle";
import ConfirmationModal from "../../components/ConfirmationModal";

export default function ListImages() {
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        async function onLoad() {
            const savedImageName = querystring('saveSuccess');
            const images = await loadImages();
            setImages(images);

            if (savedImageName) {
                setSuccessMessage(`Successfully saved ${savedImageName}!`)
            }

            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function loadImages() {
        return API.get('hpu-hideaway', '/images');
    }

    function deleteImage(id) {
        return API.del('hpu-hideaway', `/images/${id}`);
    }

    function onDeleteClick(image) {
        setImageToDelete(image);
        setShowDeleteModal(true);
    }

    async function onConfirmDelete() {
        setShowDeleteModal(false);
        const result = await deleteImage(imageToDelete.sk);;
        if (result.status) {
            setImages(await loadImages());
            setSuccessMessage(
                `Image ${imageToDelete.imageName} has been deleted.`
            );
            // Remove the old image from S3
            await Storage.vault.remove(imageToDelete.imageUrl);
        } else {
            setErrorMessage("There was an issue deleting the image.");
        }
    }

    return (
        !isLoading &&
        <div className="images container-fluid">
            {errorMessage.length > 0 && (
                <Alert
                    variant="danger"
                    className="mt-4"
                    onClose={() => setErrorMessage("")}
                    dismissible
                >
                    {errorMessage}
                </Alert>
            )}
            {successMessage.length > 0 && (
                <Alert
                    variant="success"
                    className="mt-4"
                    onClose={() => setSuccessMessage("")}
                    dismissible
                >
                    {successMessage}
                </Alert>
            )}
            <h2>Images</h2>
            <div>
                <Button
                    className="float-right mb-2"
                    variant="primary"
                    href="/image"
                >
                    Add Image
                </Button>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {images?.length > 0 ? (
                        <>
                            {images.map((image) =>
                                <tr key={image.sk}>
                                    <td>{image.imageName}</td>
                                    <td>{image.description}</td>
                                    <td className="text-center">
                                        <Dropdown align="end">
                                            <Dropdown.Toggle as={ActionsToggle} />
                                            <Dropdown.Menu>
                                                <Dropdown.Item href={`/image/${image.sk}`}>
                                                    Update
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => onDeleteClick(image)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )}
                        </>
                    ) : (
                        <tr>
                            <td colSpan={4} className="text-center">There are no images yet.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {showDeleteModal && (
                <ConfirmationModal
                    title="Delete Image"
                    messageText={`Are you sure you want to delete the image named ${imageToDelete.imageName}?`}
                    cancelButtonText="Close"
                    confirmButtonText="Confirm Deletion"
                    onConfirmCallback={onConfirmDelete}
                    onCLoseCallback={() => setShowDeleteModal(false)}
                ></ConfirmationModal>
            )}
        </div>
    );
}
