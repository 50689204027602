import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Alert, Button, Form } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { useFormFields } from "../../libs/formsLib";
import '../../themes/user-theme.css'
import './Booking.css'
import PriceCalculator from '../../libs/pricingLib';
import NumberFormat from 'react-number-format';
import { format, parseISO, subDays } from 'date-fns';

export default function Booking() {
    const history = useHistory();
    const priceCalculator = PriceCalculator();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [priceDetails, setPriceDetails] = useState(null);
    const [fields, handleFieldChange] = useFormFields({
        checkinDate: "",
        checkoutDate: "",
        bookingPrice: "",
        guestName: "",
        guestEmail: "",
        guestCount: "",
        guestComments: "",
    });

    useEffect(() => {
        async function onLoad() {
            if (id) {
                const booking = await loadBooking();
                fields.checkinDate = format(parseISO(booking.checkinDate), "yyyy-MM-dd");
                fields.checkoutDate = format(parseISO(booking.checkoutDate), "yyyy-MM-dd");
                fields.bookingPrice = booking.bookingPrice;
                fields.guestName = booking.guestName;
                fields.guestEmail = booking.guestEmail;
                fields.guestCount = booking.guestCount;
                fields.guestComments = booking.guestComments;
            }
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function loadBooking() {
        return API.get('hpu-hideaway', `/bookings/${id}`);
    }

    function saveBooking() {
        const data = {
            checkinDate: fields.checkinDate,
            checkoutDate: fields.checkoutDate,
            bookingPrice: priceDetails.totalPrice,
            priceDetails: priceDetails,
            guestName: fields.guestName,
            guestEmail: fields.guestEmail,
            guestCount: fields.guestCount,
            guestComments: fields.guestComments,
        };
        if (id) {
            // Update existing booking
            return API.put('hpu-hideaway', `/bookings/${id}`, {
                body: data
            });
        } else {
            // Create new booking
            return API.post('hpu-hideaway', '/bookings', {
                body: data
            });
        }
    }

    async function onSaveClick() {
        const result = await saveBooking();
        if (result.status || result.sk) {
            history.push(`/bookings?saveSuccess=${fields.guestName}`);
        } else {
            setErrorMessage("There was an error saving the booking.");
        }
    }

    async function calculatePrice() {
        if (isDate(fields.checkinDate) && isDate(fields.checkoutDate)) {
            const priceObj = await priceCalculator.calculateTotalCost(new Date(fields.checkinDate), subDays(new Date(fields.checkoutDate), 1));
            setPriceDetails(priceObj);
            await handleFieldChange({ target: { id: 'bookingPrice', value: priceObj.totalPrice } });
        }
    }

    function isDate(string) {
        const date = new Date(string);
        return !isNaN(date);
    }

    return (
        !isLoading &&
        <div className="booking container-fluid">
            {errorMessage.length > 0 && (
                <Alert
                    variant="danger"
                    className="mt-4"
                    onClose={() => setErrorMessage("")}
                    dismissible
                >
                    {errorMessage}
                </Alert>
            )}
            <h2>Create Booking</h2>
            <Form>
                <Form.Group className="mb-3" controlId="guestName">
                    <Form.Label>Guest Name</Form.Label>
                    <Form.Control type="text" onChange={handleFieldChange} value={fields.guestName} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="guestEmail">
                    <Form.Label>Guest Email</Form.Label>
                    <Form.Control type="text" onChange={handleFieldChange} value={fields.guestEmail} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="checkinDate">
                    <Form.Label>Check In Date</Form.Label>
                    <Form.Control type="date" onChange={handleFieldChange} onBlur={calculatePrice} value={fields.checkinDate} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="checkoutDate">
                    <Form.Label>Check Out date</Form.Label>
                    <Form.Control type="date" onChange={handleFieldChange} onBlur={calculatePrice} value={fields.checkoutDate} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="guestCount">
                    <Form.Label>Guest Count</Form.Label>
                    <Form.Control type="text" onChange={handleFieldChange} value={fields.guestCount} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="guestComments">
                    <Form.Label>Guest Comments</Form.Label>
                    <Form.Control type="text" onChange={handleFieldChange} value={fields.guestComments} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Booking Price</Form.Label>
                    <NumberFormat
                        value={fields.bookingPrice}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="$"
                        className='d-block'
                    />
                </Form.Group>
                <Button variant="primary" onClick={onSaveClick} disabled={!(fields.guestName.length && fields.guestEmail.length && fields.checkinDate.length && fields.checkoutDate.length)}>
                    Save
                </Button>
                <Button variant="secondary" href="/bookings" className="float-right">
                    Cancel
                </Button>
            </Form>
        </div>
    );
}
