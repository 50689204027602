import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import BookingReview from './containers/BookingReview';
import BookingPayment from './containers/BookingPayment';
import Login from './containers/Login';
import Signup from './containers/Signup';
import NotFound from './containers/NotFound';
import Amenity from './containers/admin/Amenity';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import HomepageLayout from './layouts/HomepageLayout';
import BasicLayout from './layouts/BasicLayout';
import AdminLayout from './layouts/AdminLayout';
import ListAmenities from './containers/admin/ListAmenities';
import ListImages from './containers/admin/ListImages';
import Image from './containers/admin/Image';
import ListCalendars from './containers/admin/ListCalendars';
import Calendar from './containers/admin/Calendar';
import Booking from './containers/admin/Booking';
import ListBookings from './containers/admin/ListBookings';
import BookingConfirmation from './containers/BookingConfirmation';
import Pricing from './containers/admin/Pricing';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <HomepageLayout />
        <Home />
      </Route>
      <Route exact path="/login">
        <BasicLayout />
        <UnauthenticatedRoute>
          <Login />
        </UnauthenticatedRoute>
      </Route>
      <Route exact path="/signup">
        <BasicLayout />
        <UnauthenticatedRoute>
          <Signup />
        </UnauthenticatedRoute>
      </Route>
      <Route exact path="/booking-review/:checkin/:checkout">
        <BasicLayout />
        <BookingReview />
      </Route>
      <Route exact path="/payments/:checkin/:checkout">
        <BasicLayout />
        <BookingPayment />
      </Route>
      <Route exact path="/confirmation/:id">
        <BasicLayout />
        <BookingConfirmation />
      </Route>
      {/* Admin routes */}
      <Route exact path="/amenities">
        <AdminLayout />
        <AuthenticatedRoute>
          <ListAmenities />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/amenity">
        <AdminLayout />
        <AuthenticatedRoute>
          <Amenity />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/amenity/:id">
        <AdminLayout />
        <AuthenticatedRoute>
          <Amenity />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/bookings">
        <AdminLayout />
        <AuthenticatedRoute>
          <ListBookings />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/booking">
        <AdminLayout />
        <AuthenticatedRoute>
          <Booking />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/booking/:id">
        <AdminLayout />
        <AuthenticatedRoute>
          <Booking />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/calendars">
        <AdminLayout />
        <AuthenticatedRoute>
          <ListCalendars />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/calendar">
        <AdminLayout />
        <AuthenticatedRoute>
          <Calendar />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/calendar/:id">
        <AdminLayout />
        <AuthenticatedRoute>
          <Calendar />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/images">
        <AdminLayout />
        <AuthenticatedRoute>
          <ListImages />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/image">
        <AdminLayout />
        <AuthenticatedRoute>
          <Image />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/image/:id">
        <AdminLayout />
        <AuthenticatedRoute>
          <Image />
        </AuthenticatedRoute>
      </Route>
      <Route exact path="/pricing">
        <AdminLayout />
        <AuthenticatedRoute>
          <Pricing />
        </AuthenticatedRoute>
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <BasicLayout />
        <NotFound />
      </Route>
    </Switch>
  );
}
