import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { API } from 'aws-amplify';
import { differenceInDays, format, parseISO, subDays } from 'date-fns';
import PriceCalculator from '../libs/pricingLib';
import BillingForm from '../components/BillingForm';
import BookingQuickview from '../components/BookingQuickview';
import config from '../config';
import '../themes/user-theme.css'
import './BookingPayment.css';

export default function BookingPayment() {
  const history = useHistory();
  const { checkin, checkout } = useParams();
  const [stripe, setStripe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setCheckinDate(parseISO(checkin));
    setCheckoutDate(parseISO(checkout));
    setStripe(window.Stripe(config.stripe.STRIPE_KEY));
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  function billUser(details) {
    return API.post("hpu-hideaway", "/billing", {
      body: details
    });
  }

  function createBooking(details) {
    return API.post("hpu-hideaway", "/bookings", {
      body: details
    });
  }

  function emailBookingConfirmation(details) {
    return API.post("hpu-hideaway", "/email", {
      body: details
    });
  }

  async function handleSubmit(fields, { token, error }) {
    if (error) {
      setErrorMessage(error);
      return;
    }

    setIsLoading(true);

    try {
      const priceCalculator = PriceCalculator();
      const priceObj = await priceCalculator.calculateTotalCost(checkinDate, subDays(checkoutDate, 1));
      const formattedCheckin = format(checkinDate, 'MM-dd-yyyy');
      const formattedCheckout = format(checkoutDate, 'MM-dd-yyyy');
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      // Bill the user (will throw an error if unsuccessful)
      await billUser({
        totalPrice: priceObj.totalPrice,
        guestEmail: fields.email,
        source: token.id
      });

      // Create booking in database
      const booking = await createBooking({
        checkinDate: checkinDate,
        checkoutDate: checkoutDate,
        bookingPrice: priceObj.totalPrice,
        priceDetails: priceObj,
        guestName: fields.name,
        guestEmail: fields.email,
        guestCount: fields.guests,
        guestComments: fields.comments,
      });

      if (booking.sk) {
        // Email booking confirmation
        await emailBookingConfirmation({
          to: fields.email,
          template: "BookingConfirmation",
          tokens: JSON.stringify({
            name: fields.name,
            numNights: differenceInDays(checkoutDate, checkinDate),
            guestCount: fields.guests || "",
            guestComments: fields.comments || "",
            checkInDate: formattedCheckin,
            checkOutDate: formattedCheckout,
            bookingPrice: currencyFormatter.format(priceObj.totalPrice)
          })
        });
        setIsLoading(false);
        history.push(`/confirmation/${booking.sk}`);
        return;
      } else {
        setIsLoading(false);
        setErrorMessage("There was a problem creating your booking. Please try again. If the problem persists, please contact us directly.");
        return;
      }
    } catch (e) {
      let message = e.toString();
      if (!(e instanceof Error) && e.message) {
        message = e.message;
      }
      setErrorMessage(message);
    }
    setIsLoading(false);
  }

  return (
    !isLoading &&
    <div className="bookingPayment">
      <section className="page-section">
        <div className="container">
          {errorMessage.length > 0 && (
            <Alert
              variant="danger"
              className="mt-4"
              onClose={() => setErrorMessage("")}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <Row className="mt-2">
            <Col lg={8}>
              <StripeProvider stripe={stripe}>
                <Elements>
                  <BillingForm isLoading={isLoading} onSubmit={handleSubmit} />
                </Elements>
              </StripeProvider>
            </Col>
            <Col lg={4} className="quickView">
              <BookingQuickview startDate={checkinDate} endDate={checkoutDate} />
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}
