import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../libs/imageLib';

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default function ImageCropper({ image, fileName, onChange }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
    const croppedImageBlobUrl = URL.createObjectURL(croppedImageBlob);
    const fileBytes = [await new Response(croppedImageBlob).arrayBuffer()];
    const croppedFile = new File(fileBytes, fileName, { type: 'image/text' });
    onChange(croppedImageBlobUrl, croppedFile);
    // eslint-disable-next-line
  }, [])

  return (
    <Cropper
      image={image}
      crop={crop}
      zoom={1}
      aspect={13 / 7}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
    />
  );
}
