import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Row, Col, ListGroup, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import config from '../config';
import ShowMore from '../components/ShowMoreLink';
import ScrollspyLink from '../components/ScrollspyLink';
import ReservationForm from '../components/ReservationForm';
import { onError } from '../libs/errorLib';
import FamilyImage from '../images/family.jpg';
import '../themes/user-theme.css'
import './Home.css';
import NumberFormat from 'react-number-format';
import DealDetailsModal from '../components/DealDetailsModal';

export default function Home() {
  const history = useHistory();

  const [images, setImages] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [basePrice, setBasePrice] = useState(0);
  const [dateSelectionDeals, setDateSelectionDeals] = useState([]);
  const [showDealDetailsModal, setShowDealDetailsModal] = useState(null);

  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllAmenities, setShowAllAmenities] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const imageList = await loadImages();
        imageList.map(async (image) => {
          const imageUrl = `https://s3.amazonaws.com/${config.s3.BUCKET}/public/${image.imageUrl}`;
          image.imageUrl = imageUrl;
        });
        setImages(imageList);

        const amenityList = await loadAmenities();
        setAmenities(amenityList);

        const calendarList = await loadCalendars();
        setCalendars(calendarList);

        const basePriceObj = await loadBasePrice();
        setBasePrice(basePriceObj.price);

        const dealList = await loadDeals();
        const dateSelectionDealList = dealList.filter((deal) => deal.type === 'dateSelection');
        setDateSelectionDeals(dateSelectionDealList);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
    // eslint-disable-next-line
  }, []);

  function loadImages() {
    return API.get('hpu-hideaway', '/images');
  }

  function loadAmenities() {
    return API.get('hpu-hideaway', '/amenities');
  }

  function loadCalendars() {
    return API.get('hpu-hideaway', '/calendars');
  }

  function loadBasePrice() {
    return API.get('hpu-hideaway', '/pricing');
  }

  function loadDeals() {
    return API.get('hpu-hideaway', '/deals');
  }

  function handleReservationSubmit(checkinDate, checkoutDate) {
    const formattedCheckin = format(checkinDate, 'yyyy-MM-dd');
    const formattedCheckout = format(checkoutDate, 'yyyy-MM-dd');
    history.push(`/booking-review/${formattedCheckin}/${formattedCheckout}`);
  }

  function renderAmenities(amenityList, numberToRender = -1) {
    return amenityList.map((amenity, i) =>
      (numberToRender === -1 || i < numberToRender) &&
      <div key={amenity.sk} className="col-lg-3 col-md-6 text-center">
        <div className="mt-5">
          <i className={'fa-solid fa-4x ' + amenity.icon + ' mb-4'}></i>
          <h3 className="h4 mb-2">{amenity.amenityName}</h3>
          <p className="text-dark-75 mb-0">{amenity.description}</p>
        </div>
      </div>
    );
  }

  function renderImages(imageList, numberToRender = -1) {
    return imageList.map((image, i) =>
      (numberToRender === -1 || i < numberToRender) &&
      <div key={image.sk} className="col-lg-4 col-sm-6">
        <div className="picture-box">
          <img className="img-fluid" src={image.imageUrl} alt={image.imageName} />
          <div className="picture-box-caption">
            <div className="project-category text-white">{image.imageName}</div>
            <div className="project-name">{image.description}</div>
          </div>
        </div>
      </div>
    );
  }

  function renderDeals() {
    const hasDateSelectionDeals = dateSelectionDeals.length > 0;
    let hasStandaloneDeal = false;
    const dateSelectionDealHtml = dateSelectionDeals.map((deal) => {
      if (deal.isStandalone) hasStandaloneDeal = true;
      return (
        <ListGroup.Item key={deal.name} className="deal">
          {deal.isStandalone && <span>**&nbsp;</span>}
          <NumberFormat
            value={deal.price}
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="$"
          /> for {deal.name} &nbsp;&nbsp;<i className="fas fa-circle-info" onClick={() => setShowDealDetailsModal(deal)}></i>
        </ListGroup.Item>
      );
    });
    return (
      <>
        {dateSelectionDealHtml}
        <ListGroup.Item className="deal">
          Extended Stay discounts
        </ListGroup.Item>
        {hasDateSelectionDeals &&
          <ListGroup.Item className="bg-white text-light-purple text-left">
            <ul>
              <li>Clicking <i className="fas fa-circle-info"></i> shows the pricing details</li>
              {hasStandaloneDeal && <li><strong>**</strong> means this is standalone pricing. No other deals affect the price of these dates.</li>}
            </ul>
          </ListGroup.Item>
        }
      </>
    );
  }

  return (
    <div className="home">
      {/* Masthead */}
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-8 align-self-end p-4 bg-primary-75">
              <h1 className="text-uppercase text-white font-weight-bold">HPU Hideaway</h1>
              <h2 className="text-white font-weight-light">Where visits to High Point feel like home!</h2>
            </div>
            <div className="col-lg-8 align-self-baseline mt-5">
              <ScrollspyLink className="btn btn-primary btn-xl text-white" to="about">Find Out More</ScrollspyLink>
            </div>
          </div>
        </div>
      </header>
      {/* About the property */}
      <section className="page-section text-light-purple" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0 text-black text-bold">Welcome to HPU Hideaway!</h2>
              <p className="mb-4">
                Nestled within a private 7-acre haven, HPU Hideaway is a unique and carefully curated retreat, born from a vision to extend
                the warm culture and exceptional hospitality synonymous with High Point University. This distinctive space is a detached
                garage, meticulously designed to provide parents of HPU students with an experience that mirrors the comfort and care found
                on campus.
              </p>
            </div>
          </div>
        </div>
        {/* property pictures */}
        <div className="photo-gallery">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              {renderImages(images, 6)}
              {showAllImages ? renderImages(images.slice(6)) : ''}
              {
                (images.length > 6 && !showAllImages) &&
                <ShowMore
                  num={images.length - 6}
                  what="images"
                  className="text-light-purple"
                  onClick={() => setShowAllImages(true)}
                />
              }
            </div>
          </div>
        </div>
        <div className="row justify-content-center py-4">
          <div className="col text-center">
            <ScrollspyLink className="btn btn-primary btn-xl text-white mx-auto mt-2" to="amenities">Explore the Amenities!</ScrollspyLink>
          </div>
        </div>
      </section>
      {/* Amenities */}
      <section className="page-section bg-dark text-black" id="amenities">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0 text-black text-bold">What we offer!</h2>
              <p className="mb-4">
                Our hope is that this Hideaway creates a space for parents to feel the same warmth and hospitality their kids experience at HPU
                daily. We've stocked the cottage with everything needed to make your stay comfy, cozy, and fun! We have a full kitchen, living
                space, and even some games for the kids. Check out our full list of amenities.
              </p>
              {amenities.length > 0 && (
                <>
                  <h3 className="mt-0">Amenities</h3>
                  <div className="row">
                    {renderAmenities(amenities, 4)}
                    {showAllAmenities ? renderAmenities(amenities.slice(4)) : ''}
                    {
                      (amenities.length > 4 && !showAllAmenities) &&
                      <ShowMore
                        num={amenities.length - 4}
                        what="amenities"
                        className="text-black"
                        onClick={() => setShowAllAmenities(true)}
                      />
                    }
                  </div>
                </>
              )}
              <ScrollspyLink className="btn btn-primary text-white btn-xl mt-5" to="reservations">Book a Stay!</ScrollspyLink>
            </div>
          </div>
        </div>
      </section>
      {/* Reservations */}
      <section className="page-section text-light-purple" id="reservations">
        <div className="container text-center">
          <Row>
            <Col md={6}>
              <h2 className="mb-4 text-black text-bold">Reservation Calendar</h2>
              {calendars.length > 0 &&
                <ReservationForm calendars={calendars} onSubmit={handleReservationSubmit} />
              }
            </Col>
            <Col md={6}>
              <h2 className="mb-4 text-black text-bold">Pricing</h2>
              <ListGroup className="text-white">
                <ListGroup.Item>
                  <NumberFormat
                    value={basePrice}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="$"
                  /> per night
                </ListGroup.Item>
                {renderDeals()}
              </ListGroup>
            </Col>
          </Row>
        </div>
        <div className="row justify-content-center pt-4 mt-4">
          <div className="col text-center">
            <ScrollspyLink className="btn btn-primary text-white btn-xl mx-auto mt-5" to="meet">Meet Your Host</ScrollspyLink>
          </div>
        </div>
      </section>
      {/* Meet the Hosts */}
      <section className="page-section bg-dark text-black" id="meet">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="mt-0 text-center text-bold">We're Pleased to Meet You!</h2>
              <div className="row">
                <div className="col-12 mb-4">
                  <p>Hey there - we are Murray and Robin White. We're thrilled to welcome you to our space, where we've brought to life a vision of creating a haven that mirrors the warmth and hospitality we found during our unforgettable years at HPU.</p>
                  <p>We met as student athletes at High Point, and thoughtfully made the decision to plant roots and grow our family in the Triad, the place we proudly call home. Our formative years at HPU were nothing short of transformative. It's where we had the values of God, Family, and Country reaffirmed, where generosity was a way of life, and where we were encouraged to dream big amidst an environment full of inspiration.</p>
                  <p>Our hope is that this Hideaway creates a space for parents to feel the same warmth and hospitality their kids experience at HPU daily.</p>
                  <p>We're here to ensure your stay is nothing short of exceptional. Whether you're returning to familiar grounds or experiencing HPU for the first time, know that you're in the hands of hosts who hold High Point University close to their hearts!</p>
                </div>
                <div className="col-12 text-center">
                  <img className="img-fluid" src={FamilyImage} alt="Host family" />
                </div>
              </div>
              <div className="text-center">
                <ScrollspyLink className="btn btn-primary text-white btn-xl mt-5" to="contact">Get in touch!</ScrollspyLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact */}
      <section className="page-section" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0 text-black text-bold">Let's Get In Touch!</h2>
              <p className="text-light-purple mb-5">Still have questions? We're happy to help!</p>
            </div>
          </div>
          <div className="row">
            <div className="col ml-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
              <a className="d-block mb-3" href="mailto:HPU.hideaway@gmail.com">HPU.hideaway@gmail.com</a>
              <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
              <a className="d-block mb-3" href="tel:3864517652">386-451-7652</a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-4 mt-4">
          <div className="col text-center">
            <ScrollspyLink className="btn btn-primary text-white btn-xl mx-auto mt-5" to="reservations">Book Now!</ScrollspyLink>
          </div>
        </div>
      </section>
      {/* Disclaimer */}
      <section className="page-section bg-dark text-black" id="disclaimer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="mt-0 text-center text-bold">We Love HPU, but We're Not Affiliated with Them!</h2>
              <div className="row">
                <div className="col-12 mb-4">
                  <p>
                    We, the hosts of HPU Hideaway, hereby declare that we are not affiliated with High Point University (HPU) in any official capacity.
                    While we are proud alumni who hold deep affection for our Alma mater and the town it resides in, our short term rental is an independent
                    endeavor created by individuals who share a common love for our university and community.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <ScrollspyLink className="btn btn-primary text-white btn-xl mt-5" to="reservations">Book Now!</ScrollspyLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-light py-5">
        <div className="container">
          <div className="small text-center text-muted"> Copyright © {(new Date()).getFullYear()} - rmmw LLC</div>
          <div className="small text-center"><Button variant="link" className="text-dark" href="#" onClick={() => history.push('/bookings')}>Login</Button></div>
        </div>
      </footer>
      {showDealDetailsModal &&
        <DealDetailsModal onClose={() => setShowDealDetailsModal(null)} deal={showDealDetailsModal} />
      }
    </div>
  );
}
