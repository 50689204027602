import React from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";

export default function ConfirmationModal({
    title,
    messageText,
    cancelButtonText,
    confirmButtonText,
    onConfirmCallback,
    onCLoseCallback,
}) {
    const handleClose = () => onCLoseCallback();
    const handleConfirm = () => onConfirmCallback();

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <CloseButton onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <p>{messageText}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="mr-auto" onClick={handleClose}>
                    {cancelButtonText}
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    {confirmButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}