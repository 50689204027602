import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { format, differenceInDays, parseISO } from 'date-fns';
import LoaderButton from '../components/LoaderButton';
import BookingQuickview from '../components/BookingQuickview';
import '../themes/user-theme.css'
import './BookingReview.css';

export default function BookingReview() {
  const history = useHistory();
  const { checkin, checkout } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [isAgreeingToRules, setIsAgreeingToRules] = useState(false);

  useEffect(() => {
    async function onLoad() {
      setCheckinDate(parseISO(checkin));
      setCheckoutDate(parseISO(checkout));
      setIsLoading(false);
    }

    onLoad();
    // eslint-disable-next-line
  }, []);

  function handleAgreementClick() {
    setIsAgreeingToRules(true);

    const formattedCheckin = format(checkinDate, 'yyyy-MM-dd');
    const formattedCheckout = format(checkoutDate, 'yyyy-MM-dd');
    history.push(`/payments/${formattedCheckin}/${formattedCheckout}`);
  }

  return (
    !isLoading &&
    <div className="bookingReview">
      <section className="page-section">
        <div className="container">
          <Row className="mt-2">
            <Col lg={8}>
              <h4 className="mt-0 font-weight-bold">{differenceInDays(checkoutDate, checkinDate)} night stay at HPU Hideaway</h4>
              <Row>
                <Col md={6}>
                  <div className="booking-date-display light-bg d-inline-block">
                    {format(checkinDate, 'MMM')} <br />
                    <span className="font-weight-bold">{format(checkinDate, 'dd')}</span>
                  </div>
                  <p className="d-inline-block ml-2">
                    {format(checkinDate, 'EEEE')} check-in <br />
                    After 4:00 PM
                  </p>
                </Col>
                <Col md={6}>
                  <div className="booking-date-display light-bg d-inline-block">
                    {format(checkoutDate, 'MMM')} <br />
                    <span className="font-weight-bold">{format(checkoutDate, 'dd')}</span>
                  </div>
                  <p className="d-inline-block ml-2">
                    {format(checkoutDate, 'EEEE')} checkout <br />
                    10:00 AM
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="icon-display d-inline-block">
                    <i className="fas fa-2x fa-unlock"></i>
                  </div>
                  <p className="d-inline-block ml-2">Self check-in with keypad</p>
                  <hr />
                </Col>
              </Row>
              {/* House Rules */}
              <Row>
                <Col><h4 className="mt-0 font-weight-bold">House Rules</h4></Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">01</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Respectful Conduct
                    <p className="text-muted">Please treat our property with the same care and respect you would your own home. Respect the tranquility of the surroundings and be mindful of noise levels, especially during quiet hours (10:00 PM to 7:00 AM)</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">02</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Smoking & Vaping
                    <p className="text-muted">Smoking or vaping is strictly prohibited inside the hideaway.</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">03</div>
                </Col>
                <Col xs={10}>
                  <div>
                    No Parties or Events
                    <p className="text-muted">We request that you refrain from hosting parties or events on the property. The hideaway is designed for quiet getaways and relaxation. For special occasions, you can partner with the host for an exception.</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">04</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Additional Guests
                    <p className="text-muted">The hideaway is designed to accommodate a specific number of guests. Please inform us in advance if you plan to have additional visitors.</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">05</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Pet Policy
                    <p className="text-muted">We understand pets are family, but unfortunately, we cannot accommodate them at this time. Please make arrangements for your furry friends elsewhere.</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">06</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Safety & Security
                    <p className="text-muted">Please ensure all doors and windows are locked when you leave the hideaway. If you have any concerns about safety or security, please don't hesitate to contact us.</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">07</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Cleanliness & Tidiness
                    <p className="text-muted">We take pride in providing a clean and well-maintained space. Please leave the hideaway in the same condition as you found it.  An excessive mess may incur additional charges</p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">08</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Appliances & Utilities
                    <p className="text-muted">
                      Please use all appliances and utilities responsibly. Turn off lights, TVs, and other appliances when not in use to conserve energy.<br />
                      <strong>The Hideaway is on a <u>septic</u>.</strong> Only toilet paper should be put in toilets.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="house-rule">
                <Col xs={2}>
                  <div className="icon-display light-bg">09</div>
                </Col>
                <Col xs={10}>
                  <div>
                    Parking
                    <p className="text-muted">Ample parking is provided for your convenience. Please park in designated areas to ensure access up and down the driveway. </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <LoaderButton block variant="primary" size="large" isLoading={isAgreeingToRules} onClick={handleAgreementClick} type="button">Agree to rules</LoaderButton>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="quickView">
              <BookingQuickview startDate={checkinDate} endDate={checkoutDate} />
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}
