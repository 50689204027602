import React, { useState } from 'react';
import { Nav, Navbar, NavItem, Container } from 'react-bootstrap';
import { Link, animateScroll as scroll } from 'react-scroll';
import ScrollspyLink from '../components/ScrollspyLink';
import logo from '../images/logo.png';

export default function HomepageLayout() {
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState('');

  function scrollToTop() {
    scroll.scrollToTop();
    setExpanded(false);
  }

  function handleNavScroll(event) {
    const element = event.srcElement.body;
    if (element && element.getBoundingClientRect().top < -100) {
      setScrolled('navbar-scrolled');
    } else {
      setScrolled('');
    }
  }

  window.addEventListener('scroll', handleNavScroll);

  return (
    <>
      <Navbar collapseOnSelect fixed="top" expand="lg" expanded={expanded} className={scrolled} id="mainNav">
        <Container>
          <Navbar.Brand>
            <Link className="navbar-brand" to="#" onClick={scrollToTop}>
              <img className="img" src={logo} alt="HPU Hideaway logo" width="25px" />&nbsp;
              HPU Hideaway
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse>
            <Nav className="ml-auto my-2 my-lg-0">
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="about">The Hideaway</ScrollspyLink></NavItem>
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="amenities">Amenities</ScrollspyLink></NavItem>
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="reservations">Reservations</ScrollspyLink></NavItem>
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="meet">Meet Your Host</ScrollspyLink></NavItem>
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="contact">Contact</ScrollspyLink></NavItem>
              <NavItem><ScrollspyLink className="nav-link" onClick={() => setExpanded(false)} to="disclaimer">Disclaimer</ScrollspyLink></NavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
