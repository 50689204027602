import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { API } from 'aws-amplify';
import { Row, Col, Form } from 'react-bootstrap';
import { onError } from '../../libs/errorLib';
import 'react-calendar/dist/Calendar.css';
import { useFormFields } from '../../libs/formsLib';
import CalendarWidget from '../../components/CalendarWidget';
import LoaderButton from '../../components/LoaderButton';

export default function Calendar() {
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCalendarData, setIsLoadingCalendarData] = useState(false);
    const [currentFullCalendarUrl, setCurrentFullCalendarUrl] = useState('');
    const [calendarData, setCalendarData] = useState(null);
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        url: "",
    });

    useEffect(() => {
        async function onLoad() {
            if (id) {

                const calendar = await loadCalendar();
                setCurrentFullCalendarUrl(calendar.calendarUrl);
                fields.name = calendar.calendarName;
                fields.url = calendar.calendarUrl;
                try {
                    const calendarData = await loadCalendarData(calendar.calendarUrl);
                    setCalendarData(calendarData);
                } catch (e) {
                    console.log(`Could not load calendar data from URL ${calendar.calendarUrl}`);
                }
            }
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function validateForm() {
        return (
            fields.name.length > 0 &&
            fields.url.length > 0 &&
            !isLoadingCalendarData
        );
    }

    function loadCalendar() {
        return API.get('hpu-hideaway', `/calendars/${id}`);
    }

    function loadCalendarData(calendarUrl) {
        return API.get('hpu-hideaway', `/calendar-data?url=${calendarUrl}`);
    }

    function saveCalendar() {
        const data = {
            calendarName: fields.name,
            calendarUrl: fields.url
        };
        if (!id) {
            // Create new calendar
            return API.post('hpu-hideaway', '/calendars', {
                body: data
            });
        } else {
            // Update existing calendar
            return API.put('hpu-hideaway', `/calendars/${id}`, {
                body: data
            });
        }
    }

    async function handleUrlChange(event) {
        const url = event.target.value;

        if (url === currentFullCalendarUrl) {
            // The calendar URL was not changed
            return;
        }

        setCurrentFullCalendarUrl(url);
        setIsLoadingCalendarData(true);

        try {
            const calendarData = await loadCalendarData(url);
            setCalendarData(calendarData);
        } catch (e) {
            console.log('Could not load calendar data from the provided URL.');
            setCalendarData(null);
        }

        setIsLoadingCalendarData(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            // Save calendar details
            await saveCalendar();
            history.push(`/calendars?saveSuccess=${fields.name}`);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }
    return (
        !isLoading &&
        <div className="calendar container-fluid">
            <Row>
                <Col lg={7} md={12}>
                    <h2>Manage Calendar</h2>
                    <div>
                        <Form onSubmit={handleSubmit}>
                            {/* Name */}
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control autoFocus type="text" value={fields.name} onChange={handleFieldChange} placeholder="Calendar name" />
                            </Form.Group>
                            {/* Calendar URL */}
                            <Form.Group controlId="url">
                                <Form.Label>Calendar URL</Form.Label>
                                <Form.Control type="text" value={fields.url} onChange={handleFieldChange} onBlur={handleUrlChange} placeholder="Calendar URL" />
                            </Form.Group>
                            <LoaderButton block size="large" variant="primary" isLoading={isLoading} disabled={!validateForm()} type="submit">Save</LoaderButton>
                        </Form>
                    </div>
                </Col>
                <Col lg={5} md={12} className="text-center">
                    <h2>Calendar Preview</h2>
                    <div>
                        <CalendarWidget key={calendarData} data={calendarData} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
