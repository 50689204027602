import React from 'react';
import { Modal } from "react-bootstrap";
import { format, isDate, parseISO } from 'date-fns';
import LoaderButton from './LoaderButton';
import NumberFormat from 'react-number-format';


export default function DealDetailsModal({ show, onClose, deal }) {
    function formatDate(date) {
        if (isDate(date))
            return format(date, "MM/dd/yyyy");
        else
            return format(parseISO(date), "MM/dd/yyyy");
    }

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header className="bg-dark" closeButton>
                <Modal.Title>Details for {deal.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-black">
                <p>
                    A price of&nbsp;
                    <NumberFormat value={deal.price} displayType="text" thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix="$" />
                    &nbsp;applies to the following dates:
                </p>
                <ul>
                    {deal.dates.map((date, index) =>
                        <li key={index}>{Array.isArray(date) ? `${formatDate(date[0])} - ${formatDate(date[1])}` : formatDate(date)}</li>
                    )}
                </ul>
                {deal.isStandalone && <p>No other deals affect the price of these dates.</p>}
            </Modal.Body>
            <Modal.Footer className="bg-dark">
                <LoaderButton block type="button" size="large" onClick={onClose}>Close</LoaderButton>
            </Modal.Footer>
        </Modal>
    );
}