import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { useFormFields } from "../../libs/formsLib";
import '../../themes/user-theme.css'
import './Amenity.css'

export default function Amenity() {
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        description: "",
        icon: "",
    });

    useEffect(() => {
        async function onLoad() {
            if (id) {
                const amenity = await loadAmenity();
                fields.name = amenity.amenityName;
                fields.description = amenity.description;
                fields.icon = amenity.icon;
            }
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function loadAmenity() {
        return API.get('hpu-hideaway', `/amenities/${id}`);
    }

    function saveAmenity() {
        const data = {
            amenityName: fields.name,
            description: fields.description,
            icon: fields.icon,
            sortOrder: 0
        };
        if (id) {
            // Update existing amenity
            return API.put('hpu-hideaway', `/amenities/${id}`, {
                body: data
            });
        } else {
            // Create new amenity
            return API.post('hpu-hideaway', '/amenities', {
                body: data
            });
        }
    }

    async function onSaveClick() {
        const result = await saveAmenity();
        if (result.status || result.sk) {
            history.push(`/amenities?saveSuccess=${fields.name}`);
        } else {
            setErrorMessage("There was an error saving the amenity.");
        }
    }

    return (
        !isLoading &&
        <div className="amenity container-fluid">
            {errorMessage.length > 0 && (
                <Alert
                    variant="danger"
                    className="mt-4"
                    onClose={() => setErrorMessage("")}
                    dismissible
                >
                    {errorMessage}
                </Alert>
            )}
            <h2>Create Amenity</h2>
            <Row>
                <Col md={7}>
                    <Form>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" onChange={handleFieldChange} value={fields.name} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" onChange={handleFieldChange} value={fields.description} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="icon">
                            <Form.Label>Icon</Form.Label>
                            <Form.Control type="text" onChange={handleFieldChange} value={fields.icon} />
                            <Form.Text><a href="https://fontawesome.com/v4/icons/" target="_blank" rel="noopener noreferrer">Icon list</a></Form.Text>
                        </Form.Group>
                        <Button variant="primary" onClick={onSaveClick} disabled={!fields.name?.length}>
                            Save
                        </Button>
                        <Button variant="secondary" href="/amenities" className="float-right">
                            Cancel
                        </Button>
                    </Form>
                </Col>
                <Col md={5} className="text-center">
                    <h2>Preview</h2>
                    <div>
                        <div key={fields.icon}>
                            <i className={'fa-solid fa-4x ' + fields.icon}></i>
                            <h3 className="h4 mb-2">{fields.icon}</h3>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
