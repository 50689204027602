import { eachDayOfInterval, format, isDate, isWithinInterval, parseISO } from 'date-fns';
import { API } from 'aws-amplify';

export default function PriceCalculator() {

  async function calculateTotalCost(startDate, endDate) {
    const calculationInterval = { start: startDate, end: endDate };
    const duration = eachDayOfInterval(calculationInterval).length;
    const basePriceObj = await loadBasePrice();
    const adminSetDailyPrices = await loadDailyPrice([startDate, endDate]);
    const dealsObj = await loadDeals();
    let dailyPriceObj = {};

    // Set the initial price for each day (base price or the price an admin set for the specific day)
    eachDayOfInterval(calculationInterval).map((date) => {
      const objPrice = adminSetDailyPrices.find((value) => value.sk === format(date, 'yyyy-MM-dd'));
      dailyPriceObj[formatDate(date)] = objPrice ? { price: Number(objPrice.price), reason: 'Special daily price', isStandalone: false } : { price: basePriceObj.price, reason: 'Base price', isStandalone: false };
      return "";
    });

    // Override daily prices with prices from any day that intersects with a "Date Selection Deal" interval
    const dateSelectionDeals = dealsObj.filter((deal) => deal.type === "dateSelection").sort((a, b) => b.isStandalone - a.isStandalone);
    dateSelectionDeals.map((deal) => {
      deal.dates.map((value) => {
        if (Array.isArray(value)) {
          const dealInterval = { start: parseISO(value[0]), end: parseISO(value[1]) };
          eachDayOfInterval(dealInterval).map((date) => {
            if (isWithinInterval(date, calculationInterval)) {
              // The deal applies
              dailyPriceObj[formatDate(date)] = { price: Number(deal.price), reason: deal.name, isStandalone: deal.isStandalone };
            }
            return "";
          })
        } else {
          const dealDate = parseISO(value);
          if (isWithinInterval(dealDate, calculationInterval)) {
            // The deal applies
            dailyPriceObj[formatDate(dealDate)] = { price: Number(deal.price), reason: deal.name, isStandalone: deal.isStandalone };
          }
        }
        return "";
      });
      return "";
    });

    // Calculate the price for the standalone deal dates that applied
    const standaloneDatesApplied = Object.values(dailyPriceObj).filter(p => p.isStandalone);
    let standalonePrice = 0;
    if (standaloneDatesApplied.length > 0) {
      standalonePrice = standaloneDatesApplied.reduce((total, currentPriceObj) => total + currentPriceObj.price, 0);
    }

    // Calculate the price for non standalone dates without time based deals
    const nonStandaloneDatesApplied = Object.values(dailyPriceObj).filter(p => !p.isStandalone);
    let dailyPriceTotal = 0;
    if (nonStandaloneDatesApplied.length > 0) {
      dailyPriceTotal = nonStandaloneDatesApplied.reduce((total, currentPriceObj) => total + currentPriceObj.price, 0);
    }

    // Find the longest time based deal that applies for the duration of the stay (calculationInterval) and apply it to the daily price total
    let timeBasedDealToApply = dealsObj.filter((deal) => deal.type === "timeBased")
      .sort((a, b) => b.numNights - a.numNights)
      .find((deal) => deal.numNights < (duration - standaloneDatesApplied.length));
    if (timeBasedDealToApply) {
      let amountToDiscount = 0;
      if (timeBasedDealToApply.isPercentDiscount) {
        amountToDiscount = dailyPriceTotal * (timeBasedDealToApply.percentDiscount / 100);
      } else {
        amountToDiscount = basePriceObj.price * timeBasedDealToApply.numNightsOff;
      }
      timeBasedDealToApply.discountAmount = amountToDiscount;
      dailyPriceTotal -= amountToDiscount;
    }

    // Finally, calculate the totalPrice by adding hte dailyPriceTotal with time based deals applied to the standalone price total
    const totalPrice = dailyPriceTotal + standalonePrice;

    const returnObject = {
      totalPrice: totalPrice,
      costBreakdown: dailyPriceObj,
      timeBasedDeal: timeBasedDealToApply
    }
    return returnObject;
  }

  function loadBasePrice() {
    return API.get('hpu-hideaway', '/pricing');
  }

  function loadDeals() {
    return API.get('hpu-hideaway', '/deals');
  }

  function loadDailyPrice(days) {
    const daysString = `${format(days[0], "yyyy-MM-dd")},${format(days[1], "yyyy-MM-dd")}`;
    return API.get('hpu-hideaway', `/pricing/daily/${daysString}`);
  }

  function formatDate(date) {
    if (isDate(date))
      return format(date, "MM/dd/yyyy");
    else
      return format(parseISO(date), "MM/dd/yyyy");
  }

  return {
    calculateTotalCost: (startDate, endDate) => calculateTotalCost(startDate, endDate)
  }
}
