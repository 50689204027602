import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormFields } from "../libs/formsLib";
import LoaderButton from './LoaderButton';
import './DateSelectionDealModal.css';

export default function DateSelectionDealModal({ show, onClose, onSave, deal }) {
    const [isLoading, setIsLoading] = useState(true);
    const [dates, setDates] = useState([]);
    const [isStandalone, setIsStandalone] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        price: "",
    });

    useEffect(() => {
        fields.name = deal?.name || "";
        fields.price = deal?.price || "";
        setIsStandalone(deal?.isStandalone || false);
        setDates(deal?.dates || []);
        setIsLoading(false);
        // eslint-disable-next-line
    }, [show]);

    function handleSingleDateChange(event, dateId) {
        const newValue = event.target.value;
        setDates(prevDates => prevDates.map((date, index) =>
            index === dateId
                ? newValue
                : date
        ));
    }

    function handleDateRangeChange(event, dateId, dateIndex) {
        const newValue = event.target.value;
        setDates(prevDates => prevDates.map((date, index) =>
            index === dateId
                ? date.map((innerDate, innerIndex) =>
                    innerIndex === dateIndex
                        ? newValue
                        : innerDate
                )
                : date
        ));
    }

    function renderDateFields() {
        return dates.map((date, index) =>
            date instanceof Array
                ? generateDateRangeFieldsHtml(date, index)
                : generateSingleDateFieldHtml(date, index)
        );
    }

    function generateSingleDateFieldHtml(date, index) {
        return <Form.Row key={`date-${index + 1}`}>
            <Form.Group as={Col} controlId={`date-${index + 1}`}>
                <Form.Label className="font-weight-bold d-flex justify-content-between"><span>Single Date</span><span role="button" className="mr-2" onClick={() => removeDate(index)}>X</span></Form.Label>
                <Form.Control type="date" defaultValue={date} onChange={(event) => handleSingleDateChange(event, index)} />
            </Form.Group>
        </Form.Row>
    }

    function generateDateRangeFieldsHtml(date, index) {
        return <Form.Row key={`date-${index + 1}`}>
            <Form.Group as={Col} controlId={`date-${index + 1}-a`}>
                <Form.Label className="font-weight-bold">From</Form.Label>
                <Form.Control type="date" defaultValue={date[0]} onChange={(event) => handleDateRangeChange(event, index, 0)} />
            </Form.Group>
            <Form.Group as={Col} controlId={`date-${index + 1}-b`}>
                <Form.Label className="font-weight-bold d-flex justify-content-between"><span>To</span><span role="button" className="mr-2" onClick={() => removeDate(index)}>X</span></Form.Label>
                <Form.Control type="date" defaultValue={date[1]} onChange={(event) => handleDateRangeChange(event, index, 1)} />
            </Form.Group>
        </Form.Row>
    }

    function addSingleDateField() {
        setDates(prevDates => [...prevDates, ""]);
    }

    function addDateRangeFields() {
        setDates(prevDates => [...prevDates, ["", ""]]);
    }

    function removeDate(dateId) {
        setDates(prevDates => prevDates.filter((_, index) => index !== dateId));
    }

    function validateForm() {
        return fields.name.length > 0 &&
            !isNaN(fields.price) > 0 && fields.price > 0 &&
            dates.length > 0
    }

    function save() {
        const dealToSave = {
            sk: deal?.sk ?? undefined,
            type: "dateSelection",
            name: fields.name,
            price: fields.price,
            isStandalone: isStandalone,
            dates: dates
        };
        onSave(dealToSave);
    }

    return (
        !isLoading &&
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Date Selection Deal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label className="font-weight-bold">Deal Name</Form.Label>
                        <Form.Control type="text" value={fields.name} onChange={handleFieldChange} placeholder="Name for the deal" autoFocus />
                    </Form.Group>
                    <Form.Group controlId="price">
                        <Form.Label className="font-weight-bold">Price</Form.Label>
                        <Form.Control type="number" value={fields.price} onChange={handleFieldChange} placeholder="Price for nights in this deal" />
                    </Form.Group>
                    <Row>
                        <Col xs="auto"><Form.Label className="font-weight-bold">Standalone Deal?</Form.Label></Col>
                        <Col xs="auto">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                onChange={() => setIsStandalone(true)}
                                checked={isStandalone}
                                id="standaloneYesCheckbox"
                            />
                        </Col>
                        <Col xs="auto">
                            <Form.Check
                                type="radio"
                                label="No"
                                onChange={() => setIsStandalone(false)}
                                checked={!isStandalone}
                                id="standaloneNoCheckbox"
                            />
                        </Col>
                    </Row>
                    <Form.Label className="font-weight-bold">Dates</Form.Label>
                    <Row>
                        <Col xs={6}>
                            <LoaderButton type="button" size="small" onClick={addSingleDateField}>Add Single Date</LoaderButton>
                        </Col>
                        <Col xs={6}>
                            <LoaderButton type="button" size="small" onClick={addDateRangeFields} className="float-right">Add Date Range</LoaderButton>
                        </Col>
                    </Row>
                    {dates.length > 0 &&
                        <div id="dates-section" className="mt-4">
                            {renderDateFields()}
                        </div>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <LoaderButton block type="button" size="large" disabled={!validateForm()} onClick={save}>Save Deal</LoaderButton>
                <LoaderButton block type="button" size="large" variant="secondary" onClick={onClose}>Close</LoaderButton>
            </Modal.Footer>
        </Modal>
    );
}