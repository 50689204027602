import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import logo from '../images/logo.png';

export default function AdminLayout() {
    const history = useHistory();
    const { userHasAuthenticated } = useAppContext();

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        history.push("/login");
    }

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">
                        <Link className="navbar-brand" to="/">
                            <img className="img" src={logo} alt="HPU Hideaway logo" width="25px" />&nbsp;
                            HPU Hideaway
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => history.push("/amenities")}>Amenities</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => history.push("/bookings")}>Bookings</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => history.push("/calendars")}>Calendars</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => history.push("/images")}>Images</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => history.push("/pricing")}>Pricing</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}