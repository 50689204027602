import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import PaymentDetails from '../components/PaymentDetails';
import PropertyImage from '../images/background-small.jpg';
import './BookingQuickview.css';

export default function BookingQuickview({ startDate, endDate, className = "", ...props }) {
  return (
    <div className={`BookingQuickview ${className}`} {...props}>
      <Row>
        <Col xs={7}>
          <h4 className="mt-0 font-weight-bold">HPU Hideaway</h4>
          <p>Entire cottage</p>
        </Col>
        <Col xs={5}>
          <Image src={PropertyImage} fluid />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <PaymentDetails startDate={startDate} endDate={endDate} />
          <hr />
          <h4 className="mt-0 font-weight-bold">Cancellation Policy</h4>
          <p>Free cancellation for 48 hours after booking.</p>
        </Col>
      </Row>
    </div>
  );
}
