import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { API, Storage } from 'aws-amplify';
import { s3Upload } from '../../libs/awsLib';
import { onError } from '../../libs/errorLib';
import { useHistory, useParams } from 'react-router-dom';
import { useFormFields } from '../../libs/formsLib';
import LoaderButton from '../../components/LoaderButton';
import ImageCropper, { readFile } from '../../components/ImageCropper';
import './Image.css';

export default function Image() {
    const history = useHistory();
    const { id } = useParams();
    const [fileChanged, setFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [croppedFile, setCroppedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [originalImageUrl, setOriginalImageUrl] = useState("");
    const [displayUrl, setDisplayUrl] = useState("");
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        description: "",
        fileName: ""
    });

    useEffect(() => {
        async function onLoad() {
            if (id) {
                try {
                    const image = await loadImage();
                    setOriginalImageUrl(image.imageUrl);
                    setDisplayUrl(await Storage.vault.get(image.imageUrl));
                    fields.name = image.imageName;
                    fields.description = image.description;
                    fields.fileName = image.imageUrl.split('-')[1]; // imageUrl format is <date>-fileName
                } catch (e) {
                    onError(e);
                }
            }
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function validateForm() {
        return (
            fields.name.length > 0 &&
            fields.description.length > 0 &&
            displayUrl !== null
        );
    }

    function loadImage() {
        return API.get('hpu-hideaway', `/images/${id}`);
    }

    function saveImage(s3ImageUrl) {
        const data = {
            imageName: fields.name,
            description: fields.description,
            imageUrl: s3ImageUrl,
            thumbnailUrl: s3ImageUrl
        };
        if (!id) {
            // Create new image
            return API.post('hpu-hideaway', '/images', {
                body: data
            });
        } else {
            // Update existing image
            return API.put('hpu-hideaway', `/images/${id}`, {
                body: data
            });
        }
    }

    async function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const imageDataUrl = await readFile(file);
            await handleFieldChange({ target: { id: 'fileName', value: file.name } });
            setDisplayUrl(imageDataUrl);
            setFileChanged(true);
            console.log('set displayUrl to', imageDataUrl);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            if (fileChanged) {
                // Remove the old image
                await Storage.vault.remove(originalImageUrl);
            }

            // Upload the new image
            const s3ImageUrl = await s3Upload(croppedFile);

            // Save image details
            await saveImage(s3ImageUrl);
            history.push('/images');
        } catch (e) {
            onError(e);
            console.log(e);
            setIsLoading(false);
        }
    }

    function handleImageCropperChange(blobUrl, croppedFile) {
        setCroppedFile(croppedFile);
        setCroppedImage(blobUrl);
    }

    return (
        !isLoading &&
        <div className="image container-fluid">
            <Row>
                <Col md={7}>
                    <h2>Image Details</h2>
                    <div>
                        <Form onSubmit={handleSubmit}>
                            {/* Name */}
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control autoFocus type="text" value={fields.name} onChange={handleFieldChange} placeholder="Image name" />
                            </Form.Group>
                            {/* Description */}
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" value={fields.description} onChange={handleFieldChange} placeholder="Short description" />
                            </Form.Group>
                            {/* Image */}
                            <Form.Group controlId="file">
                                <Form.Label>Image</Form.Label>
                                <Form.Control onChange={handleFileChange} type="file" />
                            </Form.Group>
                            {displayUrl && (
                                <div className="crop-container">
                                    <ImageCropper image={displayUrl} fileName={fields.fileName} onChange={handleImageCropperChange} />
                                </div>
                            )}
                            <LoaderButton size="large" isLoading={isLoading} disabled={!validateForm()} type="submit">Save</LoaderButton>
                            <Button variant="secondary" href="/images" className="float-right">
                                Cancel
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col md={5} className="text-center">
                    <h2>Preview</h2>
                    <div>
                        <div key={croppedImage}>
                            {croppedImage
                                ? <img src={croppedImage} className="preview" alt="Crop preview" />
                                : <i className={'fa-solid fa-4x fa-question-circle'}></i>
                            }
                            <h3 className="h4 mb-2">{fields.name}</h3>
                            <p className="text-white-75 mb-0">{fields.description}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}