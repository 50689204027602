import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

export default function BasicLayout() {
    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">
                        <Link className="navbar-brand" to="/">
                            <img className="img" src={logo} alt="HPU Hideaway logo" width="25px" />&nbsp;
                            HPU Hideaway
                        </Link>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    );
}