import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { API } from "aws-amplify";
import { differenceInDays, format } from 'date-fns';
import '../themes/user-theme.css'
import './BookingConfirmation.css';
import LoaderButton from "../components/LoaderButton";
import NumberFormat from "react-number-format";


export default function BookingConfirmation() {
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [booking, setBooking] = useState(true);

    useEffect(() => {
        async function onLoad() {
            setBooking(await loadBooking());
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function loadBooking() {
        return API.get('hpu-hideaway', `/bookings/${id}`);
    }

    return (
        !isLoading &&
        <div className="bookingConfirmation container d-flex justify-content-center mt-5">
            <div>
                <h2 className="text-center">Your booking has been confirmed!</h2>
                <p className="mt-4">Thank you for booking your {differenceInDays(new Date(booking.checkoutDate), new Date(booking.checkinDate))} night stay at HPU Hideaway.</p>
                <p>
                    You will receive an email with the booking details from the email address <a href="mailto:hpuhideaway@gmail.com">HPUHideaway@gmail.com</a>.
                    You can reply directly to that email for any further communications with the hosts.
                    If you don't see the email after a few minutes, please check your junk/spam folder.
                </p>
                <Table className="mt-5">
                    <tbody>
                        <tr>
                            <td><strong>Check In Date</strong></td>
                            <td>{format(new Date(booking.checkinDate), 'MM/dd/yyyy')}</td>
                        </tr>
                        <tr>
                            <td><strong>Check Out Date</strong></td>
                            <td>{format(new Date(booking.checkoutDate), 'MM/dd/yyyy')}</td>
                        </tr>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{booking.guestName}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{booking.guestEmail}</td>
                        </tr>
                        <tr>
                            <td><strong>Guest Count</strong></td>
                            <td>{booking.guestCount}</td>
                        </tr>
                        <tr>
                            <td><strong>Comments</strong></td>
                            <td>{booking.guestComments}</td>
                        </tr>
                        <tr>
                            <td><strong>Booking Price</strong></td>
                            <td>
                                <NumberFormat
                                    value={booking.bookingPrice}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix="$"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <LoaderButton block className="mt-5" variant="primary" size="large" isLoading={false} onClick={() => history.push("/")} type="button">Back to homepage</LoaderButton>
                <LoaderButton block className="mt-2" variant="secondary" size="large" isLoading={false} onClick={() => window.print()} type="button">Print Page</LoaderButton>
            </div>
        </div>
    );
}