import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Dropdown, Form, Row, Spinner, Table } from "react-bootstrap";
import Calendar from 'react-calendar';
import { eachDayOfInterval, format, isDate, parseISO, subDays } from 'date-fns';
import LoaderButton from "../../components/LoaderButton";
import TimeBasedDealModal from "../../components/TimeBasedDealModal";
import DateSelectionDealModal from "../../components/DateSelectionDealModal";
import { ActionsToggle } from "../../components/ActionsToggle";
import { API } from "aws-amplify";
import ConfirmationModal from "../../components/ConfirmationModal";
import NumberFormat from "react-number-format";
import './Pricing.css';
import PriceCalculator from "../../libs/pricingLib";

export default function Pricing() {
    const priceCalculator = PriceCalculator();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDailyPrice, setIsLoadingDailyPrice] = useState(false);
    const [basePrice, setBasePrice] = useState(0);
    const [deals, setDeals] = useState([]);
    const [dayPrice, setDayPrice] = useState(0);
    const [selectedDaysText, setSelectedDaysText] = useState("");
    const [isSavingBasePrice, setIsSavingBasePrice] = useState(false);
    const [isSavingDayPrice, setIsSavingDayPrice] = useState(false);
    const [isDayPriceFormShowing, setIsDayPriceFormShowing] = useState(false);
    const [isMixedDayPricesShowing, setIsMixedDayPricesShowing] = useState(false);
    const [mixedDailyPriceValues, setMixedDailyPriceValues] = useState([]);
    const [totalCostDetails, setTotalCostDetails] = useState(null);
    const [calendarValue, setCalendarValue] = useState(new Date()); // if isSelectRange = true, value is an array of 2 dates
    const [isSelectRange, setIsSelectRange] = useState(false);
    const [currentDeal, setCurrentDeal] = useState(null);
    const [isShowingDeleteDealModal, setIsShowingDeleteDealModal] = useState(false);
    const [isShowingTimeBasedDealModal, setIsShowingTimeBasedDealModal] = useState(false);
    const [isShowingDateSelectionDealModal, setIsShowingDateSelectionDealModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        async function onLoad() {
            const basePriceObj = await loadBasePrice();
            setBasePrice(basePriceObj.price);
            setDeals(await loadDeals());
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line
    }, []);

    function loadBasePrice() {
        return API.get('hpu-hideaway', '/pricing');
    }

    function saveBasePrice() {
        // Update base price
        return API.put('hpu-hideaway', `/pricing`, {
            body: {
                price: basePrice
            }
        });
    }

    function loadDeals() {
        return API.get('hpu-hideaway', '/deals');
    }

    function saveDeal(deal) {
        if (deal.sk) {
            // Update existing deal
            return API.put('hpu-hideaway', `/deals/${deal.sk}`, {
                body: deal
            });
        } else {
            // Create new deal
            return API.post('hpu-hideaway', '/deals', {
                body: deal
            });
        }
    }

    function deleteDeal(dealId) {
        return API.del('hpu-hideaway', `/deals/${dealId}`);
    }

    function loadDailyPrice(days) {
        const daysString = Array.isArray(days)
            ? `${format(days[0], "yyyy-MM-dd")},${format(days[1], "yyyy-MM-dd")}`
            : format(days, "yyyy-MM-dd");

        return API.get('hpu-hideaway', `/pricing/daily/${daysString}`);
    }

    function saveDailyPrice(data) {
        return API.put('hpu-hideaway', '/pricing/daily', {
            body: data
        });
    }

    async function handleSubmitBasePriceForm(event) {
        event.preventDefault();
        setIsSavingBasePrice(true);
        const result = await saveBasePrice();
        if (result.status || result.sk) {
            setSuccessMessage("The base price was saved successfully!");
        } else {
            setErrorMessage("There was an error saving the base price.");
        }
        setIsSavingBasePrice(false);
    }

    async function handleSubmitDayPriceForm(event) {
        event.preventDefault();
        setIsSavingDayPrice(true);
        let data = [];
        if (Array.isArray(calendarValue)) {
            const datesToUpdate = eachDayOfInterval({ start: calendarValue[0], end: calendarValue[1] });
            data = datesToUpdate.map((date) => {
                return {
                    day: format(date, "yyyy-MM-dd"),
                    price: dayPrice
                };
            })
        } else {
            data = [{
                day: format(calendarValue, "yyyy-MM-dd"),
                price: dayPrice
            }]
        }
        const result = await saveDailyPrice(data);
        if (result.status) {
            setSuccessMessage("The daily price was saved successfully!");
        } else {
            setErrorMessage("There was an error saving the daily price.");
        }
        //setCalendarValue(new Date());
        setIsSelectRange(false);
        setIsMixedDayPricesShowing(false);
        setIsSavingDayPrice(false);
    }

    async function calendarOnChange(value, event) {
        setIsLoadingDailyPrice(true);
        setIsDayPriceFormShowing(false);
        const result = await loadDailyPrice(value);
        setCalendarValue(value);
        if (isSelectRange) {
            const isSameDaySelected = formatDate(value[0]) === formatDate(value[1]);
            const numDays = !isSameDaySelected ? eachDayOfInterval({ start: value[0], end: value[1] }).length : 1;
            if (!isSameDaySelected) {
                const totalCostObj = await priceCalculator.calculateTotalCost(value[0], subDays(value[1], 1));
                setTotalCostDetails(totalCostObj);
            }
            setSelectedDaysText(`${formatDate(value[0])} - ${formatDate(value[1])}`);
            if (result.length === 0) {
                // No price explicitly set for this day; use the base price
                setDayPrice(basePrice);
            } else if (result.length === numDays && result.every((val, i, result) => val.price === result[0].price)) {
                // The price for all selected days is the same
                setDayPrice(result[0].price);
            } else {
                // The range of dates contains mixed prices
                setMixedDailyPriceValues(result);
                setDayPrice("");
                setIsMixedDayPricesShowing(true);
            }
        } else {
            setSelectedDaysText(formatDate(value));
            if (result === -1) {
                setDayPrice(basePrice);
            } else {
                setDayPrice(result.price);
            }
        }

        setIsLoadingDailyPrice(false);
        setIsDayPriceFormShowing(true);
    }

    function onUpdateDealClick(deal) {
        setCurrentDeal(deal);
        if (deal.type === "timeBased") {
            setIsShowingTimeBasedDealModal(true);
        } else {
            setIsShowingDateSelectionDealModal(true);
        }
    }

    function onAddDealClick(type) {
        setCurrentDeal(null);
        if (type === "timeBased") {
            setIsShowingTimeBasedDealModal(true);
        } else {
            setIsShowingDateSelectionDealModal(true);
        }
    }

    async function onSaveDeal(deal) {
        setCurrentDeal(null);
        setIsShowingTimeBasedDealModal(false);
        setIsShowingDateSelectionDealModal(false);
        const result = await saveDeal(deal);
        if (result.status || result.sk) {
            setDeals(await loadDeals());
            setSuccessMessage(`The deal named ${deal.name} was saved successfully!`);
        } else {
            setErrorMessage("There was an error saving the deal.");
        }
    }

    function onDeleteDealClick(deal) {
        setCurrentDeal(deal);
        setIsShowingDeleteDealModal(true);
    }

    async function onConfirmDelete() {
        setIsShowingDeleteDealModal(false);
        const result = await deleteDeal(currentDeal.sk);
        if (result.status) {
            setDeals(await loadDeals());
            setSuccessMessage(
                `Deal ${currentDeal.name} has been deleted.`
            );
        } else {
            setErrorMessage("There was an issue deleting the deal.");
        }
        setCurrentDeal(null);
    }

    function formatDate(date) {
        if (isDate(date))
            return format(date, "MM/dd/yyyy");
        else
            return format(parseISO(date), "MM/dd/yyyy");
    }

    return (
        !isLoading ? (
            <div className="pricing container-fluid">
                {errorMessage.length > 0 && (
                    <Alert
                        variant="danger"
                        className="mt-4"
                        onClose={() => setErrorMessage("")}
                        dismissible
                    >
                        {errorMessage}
                    </Alert>
                )}
                {successMessage.length > 0 && (
                    <Alert
                        variant="success"
                        className="mt-4"
                        onClose={() => setSuccessMessage("")}
                        dismissible
                    >
                        {successMessage}
                    </Alert>
                )}
                <h2>Manage Pricing</h2>
                <Row>
                    <Col lg={6}>
                        {/* Base Price section */}
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmitBasePriceForm}>
                                    <Form.Group controlId="price">
                                        <Form.Label><h4>Base Price</h4></Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="number"
                                            value={basePrice} onChange={(e) => setBasePrice(Number(e.target.value))}
                                            placeholder="Base price for a day at the property"
                                        />
                                    </Form.Group>
                                    <LoaderButton type="submit" size="large" className="float-right" isLoading={isSavingBasePrice} disabled={isSavingBasePrice}>Save</LoaderButton>
                                </Form>
                            </Col>
                        </Row>
                        {/* Deals section */}
                        <Row className="my-5">
                            <Col>
                                <h4>Deals</h4>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Details</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deals.length > 0
                                            ? deals.map((deal) =>
                                                <tr key={deal.sk}>
                                                    <td>{deal.name}</td>
                                                    <td>{deal.type === "timeBased" ? "Time Based" : "Date Selection"}</td>
                                                    <td>
                                                        {deal.type === "timeBased"
                                                            ? `Applies a ${deal.isPercentDiscount ? `${deal.percentDiscount}%` : `${deal.numNightsOff} night`} discount after ${deal.numNights} nights`
                                                            : <>
                                                                A price of ${deal.price} applies for the following dates:<br />
                                                                {deal.dates.map((date, index) =>
                                                                    <span key={index}>{date instanceof Array ? `${formatDate(date[0])} - ${formatDate(date[1])}` : formatDate(date)}<br /></span>
                                                                )}
                                                                {deal.isStandalone && <span>Standalone deal: Other deals don't affect the price of these days.</span>}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <Dropdown align="end">
                                                            <Dropdown.Toggle as={ActionsToggle} />
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => onUpdateDealClick(deal)}>
                                                                    Update
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={(e) => onDeleteDealClick(deal)}>
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                            : <tr><td colSpan={4} className="text-center">No deals have been added.</td></tr>
                                        }
                                    </tbody>
                                </Table>
                                <LoaderButton type="button" size="large" className="deal-button float-left" onClick={() => onAddDealClick("timeBased")}>Add Time Based Deal</LoaderButton>
                                <LoaderButton type="button" size="large" className="deal-button float-right" onClick={() => onAddDealClick("dateSelection")}>Add Date Selection Deal</LoaderButton>
                            </Col>
                        </Row>
                    </Col>
                    {/* Daily pricing calendar section */}
                    <Col lg={6}>
                        <h4>Daily Pricing</h4>
                        <Row>
                            <Col xs={12}>
                                <Calendar onChange={calendarOnChange} value={calendarValue} selectRange={isSelectRange} />
                            </Col>
                            <Col xs={12}>
                                <Form>
                                    <Form.Check
                                        type="checkbox"
                                        label="Select a date range"
                                        value={isSelectRange}
                                        checked={isSelectRange}
                                        onChange={(e) => setIsSelectRange(!isSelectRange)}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {isLoadingDailyPrice && <Spinner animation="border" role="status" />}
                                {isDayPriceFormShowing &&
                                    <>
                                        {isMixedDayPricesShowing && isSelectRange &&
                                            <>
                                                <p className="mt-4"><strong>The dates have mixed prices</strong></p>
                                                <ul>
                                                    {eachDayOfInterval({ start: calendarValue[0], end: calendarValue[1] }).map((date) => {
                                                        const objPrice = mixedDailyPriceValues.find((value) => value.sk === format(date, 'yyyy-MM-dd'));
                                                        if (objPrice) {
                                                            return (
                                                                <li key={date}>
                                                                    {formatDate(date)}: <NumberFormat
                                                                        value={objPrice.price}
                                                                        displayType="text"
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        prefix="$"
                                                                    />
                                                                </li>
                                                            )
                                                        } else {
                                                            return <li key={date}>{formatDate(date)}: Not set</li>;
                                                        }

                                                    })}
                                                </ul>
                                            </>
                                        }
                                        <Form onSubmit={handleSubmitDayPriceForm} className="mt-4">
                                            {/* Daily Price */}
                                            <Form.Group controlId="basePrice">
                                                <Form.Label>Price for {selectedDaysText}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={dayPrice} onChange={(e) => setDayPrice(e.target.value)}
                                                    placeholder={`Price for this ${isSelectRange ? "date range" : "day"}`}
                                                />
                                            </Form.Group>
                                            <LoaderButton type="submit" size="large" className="float-right" isLoading={isSavingDayPrice} disabled={isSavingDayPrice}>Update</LoaderButton>
                                        </Form>
                                        {isSelectRange && Array.isArray(calendarValue) && totalCostDetails &&
                                            <>
                                                <p className="mt-4"><strong>Price Breakdown:</strong></p>
                                                <ul>
                                                    {Object.entries(totalCostDetails.costBreakdown).map(([day, details]) =>
                                                        <li key={day}> {day}: <NumberFormat
                                                            value={details.price}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            prefix="$" />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;{details.reason}
                                                            {details.isStandalone &&
                                                                <>&nbsp;&nbsp;&nbsp;&nbsp;standalone price</>
                                                            }
                                                        </li>
                                                    )}
                                                    {totalCostDetails.timeBasedDeal &&
                                                        <li>Time based deal {totalCostDetails.timeBasedDeal.name} for a ${totalCostDetails.timeBasedDeal.discountAmount} discount was applied.</li>
                                                    }
                                                    <li>
                                                        Total:
                                                        <NumberFormat
                                                            value={totalCostDetails.totalPrice}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            prefix="$"
                                                        />
                                                    </li>
                                                </ul>
                                            </>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Deal modals */}
                <TimeBasedDealModal show={isShowingTimeBasedDealModal} onClose={() => setIsShowingTimeBasedDealModal(false)} onSave={onSaveDeal} deal={currentDeal} />
                <DateSelectionDealModal show={isShowingDateSelectionDealModal} onClose={() => setIsShowingDateSelectionDealModal(false)} onSave={onSaveDeal} deal={currentDeal} />
                {isShowingDeleteDealModal && (
                    <ConfirmationModal
                        title="Delete Deal"
                        messageText={`Are you sure you want to delete the deal named ${currentDeal.name}?`}
                        cancelButtonText="Close"
                        confirmButtonText="Confirm Deletion"
                        onConfirmCallback={onConfirmDelete}
                        onCLoseCallback={() => setIsShowingDeleteDealModal(false)}
                    ></ConfirmationModal>
                )}
            </div>
        ) : (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>
                <div>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                </div>
            </Container>
        )
    );
}