import React, { useEffect, useState } from 'react';
import { Button, Table, Popover, OverlayTrigger, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { differenceInDays, format, subDays } from 'date-fns';
import PriceCalculator from '../libs/pricingLib';
import './PaymentDetails.css';

export default function PaymentDetails({ startDate, endDate, className = "", ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [priceDetails, setPriceDetails] = useState(true);

  useEffect(() => {
    async function onLoad() {
      const priceCalculator = PriceCalculator();
      const priceObj = await priceCalculator.calculateTotalCost(startDate, subDays(endDate, 1));
      setPriceDetails(priceObj);
      setIsLoading(false);
    }

    onLoad();
    // eslint-disable-next-line
  }, []);

  function generatePriceReasonMap() {
    const priceReasonMap = new Map();
    let specialDailyPrices = 0;
    Object.entries(priceDetails.costBreakdown).map(([day, details]) => {
      if (details.reason === "Special daily price") {
        let setNewReason = true;
        for (let i = 1; i <= specialDailyPrices; i++) {
          const price = priceReasonMap.get(`${details.reason}_${i}`);
          if (price === details.price) {
            setNewReason = false;
            break;
          }
        }
        if (setNewReason) {
          priceReasonMap.set(`${details.reason}_${++specialDailyPrices}`, details.price);
        }
      } else {
        priceReasonMap.set(details.reason, details.price);
      }
      return "";
    });
    return priceReasonMap;
  }

  function separateDateRanges(dateStringsArray) {
    const datesArray = dateStringsArray.map((dateString) => new Date(dateString));
    const sortedDates = datesArray.sort((a, b) => a - b);
    const ranges = [];
    let currentRange = [sortedDates[0]];

    for (let i = 1; i < sortedDates.length; i++) {
      if (differenceInDays(sortedDates[i], sortedDates[i - 1]) > 1) {
        ranges.push(currentRange);
        currentRange = [sortedDates[i]];
      } else {
        currentRange.push(sortedDates[i]);
      }
    }
    ranges.push(currentRange);

    const formattedRanges = ranges.map(range => `${format(range[0], "MM/dd/yyyy")} - ${format(range[range.length - 1], "MM/dd/yyyy")}`);
    return formattedRanges;
  }

  function formatPriceDetails() {
    const priceReasonMap = generatePriceReasonMap();
    const priceDetailsArray = [];

    for (let [reason, price] of priceReasonMap) {
      const baseReason = reason.startsWith("Special daily price") ? "Special daily price" : reason;
      const nightsForReason = Object.entries(priceDetails.costBreakdown)
        .filter(([day, details]) => details.reason === baseReason && details.price === price)
        .map(([day, reason]) => day);
      priceDetailsArray.push({
        price: price,
        nights: separateDateRanges(nightsForReason),
        numNights: nightsForReason.length,
        reason: baseReason,
        total: price * nightsForReason.length
      });
    };

    priceDetailsArray.sort((a, b) => a.reason === 'Base price' ? -1 : 1);

    return (
      <Table>
        <tbody>
          {priceDetailsArray.map((details) =>
            <tr key={details.price}>
              <td>
                <OverlayTrigger
                  trigger="click"
                  overlay={
                    <Popover>
                      <Popover.Title as="h3">
                        {details.reason}:&nbsp;
                        <NumberFormat
                          value={details.price}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="$"
                        />
                      </Popover.Title>
                      <Popover.Content>
                        <p>This price applies to the following date(s)</p>
                        <ul>
                          {details.nights.map((dateRange) =>
                            <li key={dateRange}>{dateRange}</li>
                          )}
                        </ul>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button className="overlay-link" variant="link">
                    <NumberFormat
                      value={details.price}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix="$"
                    /> x {details.numNights} nights
                  </Button>
                </OverlayTrigger>
              </td>
              <td>
                <NumberFormat
                  value={details.total}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="$"
                />
              </td>
            </tr>
          )}
          {priceDetails.timeBasedDeal &&
            <tr>
              <td>
                {priceDetails.timeBasedDeal.isPercentDiscount ? (
                  `${priceDetails.timeBasedDeal.percentDiscount}% off for ${priceDetails.timeBasedDeal.name}`
                ) : (
                  `${priceDetails.timeBasedDeal.name}`
                )}
              </td>
              <td>
                <NumberFormat
                  value={priceDetails.timeBasedDeal.discountAmount}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="-$"
                />
              </td>
            </tr>
          }
          <tr>
            <td colSpan="2"><hr /></td>
          </tr>
          <tr>
            <td className="font-weight-bold">Total</td>
            <td className="text-right font-weight-bold">
              <NumberFormat
                value={priceDetails.totalPrice}
                displayType="text"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix="$"
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    !isLoading ? (
      <div className={`PaymentDetails ${className}`} {...props}>
        {formatPriceDetails()}
      </div>
    )
      : <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
  )
}
