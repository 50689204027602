import React from "react";

export const ActionsToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#nowhere"
    className="p-2"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className={'fa-solid fa-ellipsis-v'}></i>
    {children}
  </a>
));
