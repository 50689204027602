import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormFields } from "../libs/formsLib";
import LoaderButton from './LoaderButton';

export default function TimeBasedDealModal({ show, onClose, onSave, deal }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isPercentDiscount, setIsPercentDiscount] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        numNights: "",
        percentDiscount: "",
        numNightsOff: "",
    });

    useEffect(() => {
        new Promise((resolve, reject) => {
            setIsLoading(true);
            resolve();
        }).then(() => {
            fields.name = deal?.name || "";
            fields.numNights = deal?.numNights || "";
            fields.percentDiscount = deal?.percentDiscount || "";
            fields.numNightsOff = deal?.numNightsOff || "";
            setIsPercentDiscount(deal?.isPercentDiscount ?? true);
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, [show]);

    function validateForm() {
        return fields.name.length > 0 &&
            !isNaN(fields.numNights) > 0 && fields.numNights > 0 &&
            (
                (isPercentDiscount && !isNaN(fields.percentDiscount) > 0 && fields.percentDiscount > 0) ||
                (!isPercentDiscount && !isNaN(fields.numNightsOff) > 0 && fields.numNightsOff > 0)
            )
    }

    function save() {
        const dealToSave = {
            sk: deal?.sk ?? undefined,
            type: "timeBased",
            name: fields.name,
            numNights: fields.numNights,
            isPercentDiscount: isPercentDiscount,
            percentDiscount: fields.percentDiscount,
            numNightsOff: fields.numNightsOff
        };
        onSave(dealToSave);
    }

    return (
        !isLoading &&
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Time Based Deal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label className="font-weight-bold">Deal Name</Form.Label>
                        <Form.Control type="text" value={fields.name} onChange={handleFieldChange} placeholder="Name for the deal" autoFocus />
                    </Form.Group>
                    <Form.Group controlId="numNights">
                        <Form.Label className="font-weight-bold">Number of Nights</Form.Label>
                        <Form.Control type="number" value={fields.numNights} onChange={handleFieldChange} placeholder="Number of nights before the deal applies" />
                    </Form.Group>
                    <Row>
                        <Col xs="auto"><Form.Label className="font-weight-bold">Deal Type</Form.Label></Col>
                        <Col xs="auto">
                            <Form.Check
                                type="radio"
                                label="Percent Discount"
                                onChange={() => setIsPercentDiscount(true)}
                                checked={isPercentDiscount}
                                id="percentDiscountCheckbox"
                            />
                        </Col>
                        <Col xs="auto">
                            <Form.Check
                                type="radio"
                                label="Nightly Discount"
                                onChange={() => setIsPercentDiscount(false)}
                                checked={!isPercentDiscount}
                                id="nightlyDiscountCheckbox"
                            />
                        </Col>
                    </Row>
                    {isPercentDiscount ? (
                        <Form.Group controlId="percentDiscount">
                            <Form.Label className="font-weight-bold">Percent Discount</Form.Label>
                            <Form.Control type="number" value={fields.percentDiscount} onChange={handleFieldChange} placeholder="Percentage of discount for the deal" />
                        </Form.Group>
                    ) : (
                        <Form.Group controlId="numNightsOff">
                            <Form.Label className="font-weight-bold">Nightly Discount</Form.Label>
                            <Form.Control type="number" value={fields.numNightsOff} onChange={handleFieldChange} placeholder="Number of nights to discount for the deal (at the base price)" />
                        </Form.Group>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <LoaderButton block type="button" size="large" disabled={!validateForm()} onClick={save}>Save Deal</LoaderButton>
                <LoaderButton block type="button" size="large" variant="secondary" onClick={onClose}>Close</LoaderButton>
            </Modal.Footer>
        </Modal>
    );
}